import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {UserService} from 'milcontratos';
// import {User} from 'firebase';
import * as firebase from 'firebase/app';

import {AlertService} from '../shared/services/alert.service';


@Component({
    selector: 'app-transition',
    templateUrl: './transition.component.html',
    styleUrls: ['./transition.component.scss']
})
export class TransitionComponent implements OnInit, OnDestroy {

    private returnUrl: string;
    private userSubscription: Subscription;
    private activatedRouteSubscription: Subscription;
    private user: firebase.User | null;
    private token: string;
    private user_received: string;
    private password_received: string;

    emailIsSent: boolean = false;
    sendingEmail: boolean = false;
    showEmailVerification = false;

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(async params => {
            this.user_received = params['usuario'];
            this.password_received = params['password'];
            this.token = params['token_servicio'];

        });
        this.userSubscription = this.userService.getCurrentUserObs().subscribe(async (res) => {
            if (res) {
                if (res.isAnonymous) {
                    await this.userService.signOut();
                    await this.router.navigate(['sign-in']);
                } else {
                    this.user = res;
                    //@if(login == 'managed')
                    if (!res.emailVerified) {
                        this.showEmailVerification = true;
                    } else {
                        if (this.returnUrl !== '/') {
                            await this.router.navigateByUrl(this.returnUrl);
                        } else {
                            await this.router.navigate(['home']);
                        }
                    }
                    //@endif
                    //@if(login == 'delegated')
                    if (this.returnUrl !== '/') {
                        await this.router.navigateByUrl(this.returnUrl);
                    } else {
                        await this.router.navigate(['home']);
                    }
                    //@endif
                }
            } else {
                if (this.token !== undefined) {
                    try {
                        console.log('here it should call signInApismartcontracts');
                        console.log('parameters received: ' + this.token + this.user_received + this.password_received);
                        const user = await this.userService
                            .signInApismartcontracts(this.token, this.user_received, this.password_received);
                        if (user) {
                            await this.router.navigate(['home']);
                            /*console.log('username: ' + user.toString());*/
                        }
                    } catch (e) {
                        console.log(e);
                        await this.router.navigate(['sign-in']);
                    }
                } else {
                    await this.router.navigate(['sign-in']);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.activatedRouteSubscription) {
            this.activatedRouteSubscription.unsubscribe();
        }
    }

    async onSendVerificationEmail() {
        if (!this.sendingEmail) {
            try {
                this.sendingEmail = true;
                await this.userService.sendEmailVerificationPlatform();
                this.emailIsSent = true;
                this.sendingEmail = false;
            } catch (e) {
                console.error(e);
                await this.alertService.showAlert();
                this.sendingEmail = false;
            }
        }
    }
}
