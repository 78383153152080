import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { saveAs } from 'file-saver';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  DocPreviewComponent,
  DocumentsService,
  OwnedDocumentsService,
  SignatureService,
  FillDocumentService,
  DocumentPurchaseState
} from 'milcontratos';
import { FilledDocument, LegalDocument, Deal, SubscriptionPlan } from 'milcontratos-database';

import { AlertService } from '../../shared/services/alert.service';
import { ValidPacks } from 'milcontratos/lib/services/client/packs.service';
import { round } from '../../shared/utils/utils';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-add-signers',
  templateUrl: './add-signers.component.html',
  styleUrls: ['./add-signers.component.scss']
})
export class AddSignersComponent implements OnInit {

  PDFStatus = 'Descarga PDF';

  document: LegalDocument;
  filledDocument: FilledDocument;

  loading: boolean = true;
  isResponsive: boolean = false;

  private documentId: string;

  pdfUrl: SafeResourceUrl;
  private pdfUrlPrefix: string = '//drive.google.com/viewerng/viewer?embedded=true&url=';

  tempCheck = {};
  tempLoading = {};

  feedbackDownload: boolean = false;

  renderedDocPreview: boolean = false;
  tabs: number = 0;

  private iva: number;
  legalAdviceAdded: boolean = false;
  packs: ValidPacks[];
  subscription: SubscriptionPlan;
  price: number;
  selectedPack: ValidPacks;
  isSubscriptionSelected: boolean = false;
  appliedDeal: Deal = undefined;

  loadingPayButtons: boolean = true;
  blockBuyButton: boolean = false;

  isPayed: boolean = false;

  documents: any[] = [];
  documentsDraft: any[] = [];
  documentsFinal: any[] = [];
  cursorPage: any;
  filter: string = '';
  docRep: any[] = [];
  private loadingDocs: boolean = true;

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('preview') preview: DocPreviewComponent;

  private file: File;
  private importedSigners = []

  constructor(private alertService: AlertService,
    private deviceDetectorService: DeviceDetectorService,
    private docService: DocumentsService,
    private fillDocumentService: FillDocumentService,
    private http: HttpClient,
    private ownedDocService: OwnedDocumentsService,
    private ownedServices: OwnedDocumentsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private signingService: SignatureService,
    private ownedService: OwnedDocumentsService,
    private documentsService: DocumentsService) {
  }

  async ngOnInit() {
    // this.documentsService.isDoc

    this.isResponsive = this.deviceDetectorService.isTablet() || this.deviceDetectorService.isMobile();
    this.documentId = this.route.snapshot.params.document_id;
    await this.getDocument();
    if (this.isResponsive || this.filledDocument.annexes.length > 0 || this.filledDocument.overwriteDocument) {
      this.loadPdf();
    }
    this.iva = await this.documentsService.getIVA();
    this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
      this.legalAdviceAdded,
      this.selectedPack !== undefined || this.isSubscriptionSelected,
      this.appliedDeal);
  }

  prevPage() {
    this.back.emit(true);
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file.type === 'text/csv' || this.file.type === 'text/plain') {
      let fileReader = new FileReader();
      fileReader.readAsText(this.file)
      fileReader.onload = (e) => {
        this.importSigns(fileReader.result);
      }
    } else if (this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || this.file.type === 'application/vnd.ms-excel') {
      let fileReader = new FileReader()
      fileReader.readAsBinaryString(this.file)
      fileReader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'binary' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const res = XLSX.utils.sheet_to_csv(worksheet)
        this.importSigns(res)
      }
    } else {
      this.alertService.showAlert('Error al importar. Solo admite .csv / .txt delimitado por comas o .xls / .xlsx.')
    }
  }

  importSigns(res) {
    res = res.slice(0, -1)

    if (res.search('\n') !== -1 || res.search('\r') !== -1 || res.search('\t') !== -1 || res.search(',') !== -1) {
      res = res.replaceAll('\r', ',').replaceAll('\n', ',').replaceAll('\t', ',')
      while (res.search(',,') !== -1) {
        res = res.replace(',,', ',')
      }
    } else {
      // this.alertService.showAlert('Error al importar. Compruebe el archivo, debe estar separado por comas.')
      this.alertService.showAlert('Error al leer los datos del archivo. Compruebe que los datos del archivo estén estructurados correctamente.')
    }

    this.importedSigners = res.split(',')

    let duplicate = this.importedSigners.some((e, i) => {
      return this.importedSigners.indexOf(e) !== i
    });

    if (!duplicate) {
      this.filledDocument.pendingSignatures.forEach((e, i) => {
        e.email = this.importedSigners[i]
      })
    } else {
      this.alertService.showAlert('Se ha encontrado un duplicado en su archivo. Por favor, revise el contenido.')
    }
  }

  isSigned(id: string): boolean {
    if (this.filledDocument.signatures) {
      for (let i = 0; i < this.filledDocument.signatures.length; i++) {
        if (this.filledDocument.signatures[i].id === id) {
          return true;
        }
      }
    }
    return false;
  }

  async sendAssignEmail(item) {
    this.tempLoading[item.id] = true;
    try {
      await this.signingService.assignEmailSign(this.documentId, item.id, item.email);
      this.tempCheck[item.id] = true;

    } catch (e) {
      await this.alertService.showAlert();
    }
    delete this.tempLoading[item.id];
  }

  async getDocument() {
    try {
      this.filledDocument = await this.ownedServices.getFilledDocument(this.documentId, null, true);
      await this.docService.getPermissionToSeeDocument(this.filledDocument.document_id);
      this.document = await this.docService.getCompletDocumentById(this.filledDocument.document_id);
    } catch (e) {
      if (e.errors[0] === 'Not in signing state') {
        await this.alertService.showAlert('No se ha podido realizar la compra de las firmas, vuelva a intentarlo.');
      } else {
        await this.alertService.showAlert('Ha habido un error al cargar el documento.');
      }
    }
  }

  async updateWholeDocument() {
    this.loading = false;
    if (!this.renderedDocPreview) {
      this.renderedDocPreview = true;

      if (this.filledDocument.signatures && this.filledDocument.signatures.length > 0) {
        // add barcode
        for (let i = 0; i < this.filledDocument.signatures.length; i++) {
          this.preview.addSignature('id' + i, this.filledDocument.signatures[i].hash);
        }
      }
      this.fillDocumentService.init(this.document, this.filledDocument, this.preview);
    }
  }

  async onChangeDocumentLanguage(language: string) {
    await this.fillDocumentService.changeLanguage(language);
  }

  async download() {
    try {
      this.feedbackDownload = true;
      const render = await this.ownedDocService.renderInProgressFilledDocuments(this.filledDocument.id);
      const subscriber = this.docService.getUrlResource(render.docxPath).subscribe((url) => {
        if (url) {
          const options = { responseType: 'blob' };
          this.http.get(url, { responseType: 'blob' }).subscribe(
            response => { // download file
              const blob = new Blob([response],
                { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
              const filename = 'document.docx';
              saveAs(blob, filename);
              this.feedbackDownload = false;
            },
            error => {
              this.feedbackDownload = false;
              console.error(`Error: ${error.message}`);
            }
          );
          subscriber.unsubscribe();
        }
      });
    } catch (e) {
      await this.alertService.showAlert();
    }
  }

  async loadPdf() {
    const render = await this.ownedDocService.renderInProgressFilledDocuments(this.filledDocument.id);
    const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
      if (url) {
        this.pdfUrl = url;
        subscriber.unsubscribe();
      }
    });
    this.loading = false;
  }

  /*async downloadDocPdf() {
   this.PDFStatus = 'Generando ...';
       try {
           const render = await this.ownedService.renderInProgressFilledDocuments(this.filledDocument.id);
           const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
               if (url) {
                   this.http.get(url, {responseType: 'blob'}).subscribe(
                       response => { // download file
                           const blob = new Blob([response],
                               {type: 'application/pdf'});
                           const filename = 'document.pdf';
                           saveAs(blob, filename);
                           this.PDFStatus = 'Descarga PDF';
                           },
                        error => {
                           console.error(`Error: ${error.message}`);
                       });
                   subscriber.unsubscribe();
               }
           });
       } catch (e) {
           await this.alertService.showAlert('Ha habido un error descargando el documento.');
           this.PDFStatus = 'Descarga PDF';
       }
   }*/
}
