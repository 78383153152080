import {
    Component, OnInit, ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { DocPreviewComponent, DocumentPurchaseState, DocumentsService, Languages, OwnedDocumentsService } from 'milcontratos';
import { FileFormat, FileType, PublicLegalDocument } from 'milcontratos-database';
import { IConverterOptionsChangeable } from 'ngx-showdown';
import { saveAs } from 'file-saver';

import { LoadedDocumentsService } from '../search-doc/loaded-documents.service';
import { AlertService } from '../shared/services/alert.service';
import { PreviousRouteService } from '../previous-route.service';
import { Subscription } from 'rxjs/Rx';


@Component({
    selector: 'app-document-info',
    templateUrl: './document-info.component.html',
    styleUrls: ['./document-info.component.scss']
})
export class DocumentInfoComponent implements OnInit {

    languages = Languages;

    document: PublicLegalDocument;
    mainLanguageDocument: PublicLegalDocument;
    documentTextId: string;
    documentLanguage: string;
    documentPreviewUrl: string = '';

    downloading: boolean = false;
    documentStates = DocumentPurchaseState;
    state: DocumentPurchaseState;

    //@if(documents == 'free')
    blockBuyButton: boolean = false;
    //@endif

    price: number;
    priceIva: number;
    private iva: number;

    routeSubscription: Subscription;

    tabs: number = 0;

    private toFilling: boolean = false;


    options: IConverterOptionsChangeable = { 'headerLevelStart': 3 };

    @ViewChild('preview') preview: DocPreviewComponent;

    constructor(private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private documentsService: DocumentsService,
        private http: HttpClient,
        private loadedDocumentsService: LoadedDocumentsService,
        private ownedDocumentService: OwnedDocumentsService,
        private router: Router,
        private location: Location,
        private previousRouteService: PreviousRouteService) {
    }

    async ngOnInit() {
        try {
            this.routeSubscription = this.activatedRoute.params.subscribe(async (params) => {
                this.documentTextId = params['id'];
                this.documentLanguage = params['language'];
                const currentUrl = this.location.path();
                this.mainLanguageDocument = await this.documentsService.getPublicDocumentBytextId(this.documentTextId);
                // if the selected language is not the main language
                if (this.documentLanguage && this.documentLanguage !== this.mainLanguageDocument.documentLanguage) {
                    this.document = await this.documentsService
                        .getPublicDocumentTranslationById(this.mainLanguageDocument.availableLanguages[this.documentLanguage]);
                } else {
                    this.document = this.mainLanguageDocument;
                    this.documentLanguage = this.document.documentLanguage;
                }
                if (this.document) {
                    // const iva = await this.documentsService.getIVA();
                    // this.price = this.document.price;
                    // this.priceIva = this.document.price + this.document.price * (iva / 100);

                    this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
                        null, false, undefined);
                    this.priceIva = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
                        null, false, undefined);
                    this.document = this.mainLanguageDocument;

                    try {
                        this.documentPreviewUrl = await this.documentsService
                            .getImagePreviewUrl(this.document.textId, this.documentLanguage).toPromise();
                    } catch (e) {
                        console.error(e);
                    }
                    this.state = await this.documentsService.getDocumentPurchaseState(this.mainLanguageDocument.id);
                } else {
                    this.router.navigate(['/search-document']);
                }
            });
        } catch (e) {
            console.error(e);
            await this.alertService.showAlert();
        }
    }

    unselectDocument() {
        this.loadedDocumentsService.setSelectedDocument(undefined);
        // const previousUrl = this.previousRouteService.getPreviousUrl();
        // const currentUrl = this.router.url;
        // if (previousUrl === currentUrl) {
        this.router.navigate(['/search-document']);
        // } else {
        //     this.location.back();
        // }
    }

    async redirectFillDocument() {
        this.toFilling = true
        const id = await this.ownedDocumentService.newFilledDocument(this.mainLanguageDocument.id);
        this.router.navigate(['/fill-document', id]);
    }

    async buyDocument() {
        try {
            if (!this.blockBuyButton) {
                this.blockBuyButton = true;
                const documentAcquisitionExceeded = await this.documentsService.hasUserExceedMaxDocumentPromotion();
                if (!documentAcquisitionExceeded) {
                    // await this.alertService.showAlert('Has superado el número máximo de documentos que puedes ' +
                    //     'obtener este mes. Deberás esperar a que comience el siguiente mes para poder obtener nuevos documentos.');
                    this.router.navigate(['/buy-document', this.mainLanguageDocument.id]);
                } else {
                    await this.alertService.showAlert('Has superado el número máximo de documentos que puedes ' +
                        'obtener este mes. Deberás esperar a que comience el siguiente mes para poder obtener nuevos documentos.');
                    // const id = await this.ownedDocumentService.tryToBuyDocumentAndFill(this.document.id);
                    // this.router.navigate(['/fill-document', id]);
                }
                this.blockBuyButton = false;
            }
        } catch (e) {
            console.error(e);
            await this.alertService.showAlert();
            this.blockBuyButton = false;
        }
    }

    async redirectToBuy() {
        this.router.navigate(['/buy-document', this.mainLanguageDocument.id]);
    }

    async startDownloadStaticDocuments() {
        if (this.document && this.document.isStaticDocument) {
            const doc = await this.documentsService.getCompletStaticDocumentById(this.mainLanguageDocument.id);
            const path = doc.document[this.documentLanguage];
            let name = doc.name;
            let contentType;

            if (path.endsWith(FileFormat.docx)) {
                name = `${name}.${FileFormat.docx}`;
                contentType = FileType.docx;
            } else if (path.endsWith(FileFormat.pptx)) {
                name = `${name}.${FileFormat.pptx}`;
                contentType = FileType.pptx;
            } else if (path.endsWith(FileFormat.pdf)) {
                name = `${name}.${FileFormat.pdf}`;
                contentType = FileType.pdf;
            }
            await this.download(path, name, contentType);
        }
    }

    private async download(path, filename, contentType) {
        try {
            this.downloading = true;
            const subscriber = this.documentsService.getUrlResource(path).subscribe((url) => {
                if (url) {
                    const options = { responseType: 'blob' };
                    this.http.get(url, { responseType: 'blob' }).subscribe(
                        response => { // download file
                            const blob = new Blob([response],
                                { type: contentType });
                            saveAs(blob, filename);
                            this.downloading = false;
                        },
                        error => {
                            this.downloading = false;
                            console.error(`Error: ${error.message}`);
                        }
                    );
                    subscriber.unsubscribe();
                }
            });
        } catch (e) {
            console.error(e);
            this.downloading = false;
            await this.alertService.showAlert('Ha habido un error al descargar el documento.');
        }
    }

    async freeDoc() {
        try {
            if (!this.blockBuyButton) {
                this.blockBuyButton = true;
                const documentAcquisitionExceeded = await this.documentsService.hasUserExceedMaxDocumentAcquisition();
                if (documentAcquisitionExceeded) {
                    await this.alertService.showAlert('Has superado el número máximo de documentos que puedes ' +
                        'obtener este mes. Deberás esperar a que empiece el mes siguiente para poder obtener nuevos documentos.');
                } else {
                    const id = await this.ownedDocumentService.tryToBuyDocumentAndFill(this.document.id);
                    this.router.navigate(['/fill-document', id]);
                }
                this.blockBuyButton = false;
            }
        } catch (e) {
            console.error(e);
            await this.alertService.showAlert();
            this.blockBuyButton = false;
        }
    }
}
