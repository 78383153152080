import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  /*try {
      console.log('cambiando worker');
      (<any>window).pdfWorkerSrc = '/assets/pdf.worker.js';
  } catch (e) {
      console.log(e);
  }*/
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
