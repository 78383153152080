import { Component, OnInit } from '@angular/core';
import {ChatService} from 'milcontratos';
import {AlertService} from '../shared/services/alert.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  name: string = '';
  email: string = '';
  issue: string[] = ['Dudas funcionales', 'Soporte técnico', 'Incidencias', 'Otros']; // TODO Fix
  selectedIssue: string = undefined;
  message: string = '';
  error: boolean = false;

  constructor(private alertService: AlertService,
              private chatService: ChatService,
              private router: Router) { }

  ngOnInit() {
  }

  async send() {
    if (this.name && this.email && this.message && this.selectedIssue !== undefined) {
      this.error = false;
      try {
        await this.chatService.contactUs(this.name, this.email, this.message, this.selectedIssue);
        await this.alertService.showAlert('El mensaje ha sido enviado correctamente', 'success', 'OK', 'Éxito');
        this.router.navigate(['/my-documents']);
      } catch (e) {
        console.log(e);
        await this.alertService.showAlert();
      }
    } else {
      this.error = true;
    }
  }

}
