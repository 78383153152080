import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DocumentPurchaseState, DocumentsService, OwnedDocumentsService } from 'milcontratos';

import { AlertService } from '../../shared/services/alert.service';
import { PublicLegalDocument } from 'milcontratos-database';
import { ChangeEvent } from 'angular2-virtual-scroll';


@Component({
  selector: 'app-documents-template',
  templateUrl: './documents-template.component.html',
  styleUrls: ['./documents-template.component.scss']
})
export class DocumentsTemplateComponent implements OnInit {

  documents: any[] = [];
  documentsDraft: any[] = [];
  documentsFinal: any[] = [];
  cursorPage: any;
  filter: string = '';
  private loadingDocs: boolean = true;

  constructor(private alertService: AlertService,
    private docServices: DocumentsService,
    private ownedServices: OwnedDocumentsService,
    private router: Router) { }

  ngOnInit() {
    this.getOwnedTemplateDocuments();

  }

  async getOwnedTemplateDocuments() {
    try {
      const doc = await this.ownedServices.getPaginateOwnedDocuments(10, this.cursorPage);
      const docDraft = await this.ownedServices.getPaginateFillingFilledDocuments(10, this.cursorPage);
      docDraft.documents = await this.checkDocumentOwnership(docDraft.documents);

      this.cursorPage = doc.cursor;
      doc.documents = await this.checkDocumentOwnership(doc.documents);

      // this.isInDraft(doc.documents, docDraft.documents);

      this.documents = doc.documents;
    } catch (e) {
      await this.alertService.showAlert();
    }
  }

  async useDocument(item) {
    try {
      const res = await this.ownedServices.newFilledDocument(item.id);
      this.router.navigate(['/fill-document', res]);
    } catch (e) {
      await this.alertService.showAlert();
    }
  }

  searchDoc(value) {
    this.filter = value.toLowerCase();
  }

  goToNewVersion(item: PublicLegalDocument) {
    this.router.navigate(['/legal-document/' + item.textId]);
  }

  async freeUpdate(item: PublicLegalDocument) {
    try {
      //console.log(item); //TODO REMOVE
      await this.docServices.purchaseFreeDocumentWithoutLegalAdvice(item.free_update_to, item.id);
      this.cursorPage = undefined;
      await this.getOwnedTemplateDocuments();
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
    }
  }

  async checkDocumentOwnership(documents: any) {
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      try {
        if (document.hasLastUpdate) {
          document.isStaticDocument = false;
          if (document.free_update_to != undefined) {
            const state = await this.docServices.getDocumentPurchaseState(document.free_update_to);
            if (state == DocumentPurchaseState.owned) {
              documents.splice(i, 1);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    return documents;
  }

  async onDetectionScrollChange(evt: ChangeEvent) {
    try {
      if (evt.start === 0) {
        // Ir para atrás.
      } else if (evt.end === this.documents.length - 1) {
        // Ir para delante.
        if (!this.loadingDocs) {
          this.loadingDocs = true;
          const doc = await this.ownedServices.getPaginateOwnedDocuments(10, this.cursorPage);
          this.cursorPage = doc.cursor;
          doc.documents = await this.checkDocumentOwnership(doc.documents);
          this.documents.push(...doc.documents);
          this.loadingDocs = false;
        }
      }
    } catch (e) {
      await this.alertService.showAlert('Ha habido un error al cargar los documentos.');
    }
  }
}
