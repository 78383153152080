import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MaterializeAction } from '@samuelberthe/angular2-materialize';
import { Subscription } from 'rxjs';
import { UserService } from 'milcontratos';

import { AlertService } from '../shared/services/alert.service';


@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {

    @Output() displaySignIn: EventEmitter<boolean> = new EventEmitter();

    modalActions: EventEmitter<string | MaterializeAction> = new EventEmitter();

    email: string = '';
    password: string = '';
    errorMsg: string = '';

    signingIn: boolean = false;
    showErrorMsg: boolean = false;

    private returnUrl: string;
    private userSubscription: Subscription;

    constructor(private alertService: AlertService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.userSubscription = this.userService.getCurrentUserObs().subscribe((res) => {
            if (res) {
                if (this.returnUrl === '/') {
                    this.router.navigateByUrl('home');
                } else {
                    this.router.navigateByUrl(this.returnUrl);
                }
            }
        });
    }

    ngAfterViewInit() {
        if (this.route.snapshot.queryParams['resetPassword']) {
            setTimeout(() => {
                this.openResetPassword();
            }, 0);
        }
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    async signIn() {
        try {
            if (!this.signingIn) {
                this.signingIn = true;
                await this.userService.platformLogin(this.email.trim(), this.password);
                // this.router.navigate(['/my-documents']);
            }
        } catch (e) {
            this.signingIn = false;
            console.error(e);

            if (e === 'User not found') {
                await this.alertService.showAlert('El usuario no existe.');
            } else if (e === 'Not valid user or password') {
                await this.alertService.showAlert('Contraseña incorrecta.');
            } else {
                await this.alertService.showAlert('Se ha producido un error.');
            }

            if (e.code === 'auth/wrong-password') {
                this.errorMsg = 'Contraseña incorrecta.';
            } else if (e.code === 'auth/user-not-found') {
                this.errorMsg = 'El usuario no existe';
            } else if (e.code === 'auth/invalid-email') {
                this.errorMsg = 'El correo no es váildo';
            }
        }
    }

    openResetPassword() {
        this.modalActions.emit({ action: 'open', params: [] });
    }

    closeResetPassword() {
        this.modalActions.emit({ action: 'close', params: [] });
    }

    async sendResetEmail() {
        try {
            await this.userService.sendResetPasswordPlatform(this.email);
            this.closeResetPassword();
            await this.alertService.showAlert('El correo se ha enviado con éxito.', 'success', 'Ok', 'Èxit');
        } catch (e) {
            console.error(e);
            this.closeResetPassword();
            await this.alertService.showAlert('Se ha producido un error al enviar el correo. Asegúrese de estar' +
                'registrado con la dirección de correo introducida.');
        }
    }

    signUpPage() {
        this.router.navigateByUrl('sign-up');
    }

}
