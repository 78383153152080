import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {OwnedDocumentsService} from 'milcontratos';
import {AlertService} from '../shared/services/alert.service';


@Component({
  selector: 'app-purchase-document-completed',
  templateUrl: './purchase-document-completed.component.html',
  styleUrls: ['./purchase-document-completed.component.scss']
})
export class PurchaseDocumentCompletedComponent implements OnInit, OnDestroy {
  documentId: string;

  private routeSubscription: Subscription;
  private blockFillDocument: boolean = false;

  constructor(private alertService: AlertService,
              private ownedDocumentService: OwnedDocumentsService,
              private route: ActivatedRoute,
              private router: Router,
              private zone: NgZone) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.documentId = params['document_id'];
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  redirectToMyDocuments() {
    this.router.navigate(['/my-documents'], {queryParams: {template: 'true'} });
  }

  redirectMyPurchases() {
    this.router.navigate(['/my-purchases']);
  }

  async redirectFillDocument() {
    try {
      if (!this.blockFillDocument) {
        this.blockFillDocument = true;
        const id = await this.ownedDocumentService.newFilledDocument(this.documentId);
        this.zone.run(() => {
          this.router.navigate(['/rellenar-documento', id]);
        });
      }
    } catch (e) {
      console.error(e);
      this.blockFillDocument = false;
      await this.alertService.showAlert();
    }
  }
}
