import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {Subscription} from 'rxjs';
import {ListDocumentsService} from 'milcontratos';
import {QuestionInterface} from 'milcontratos-database';

import {LoadedDocumentsService} from '../loaded-documents.service';
import {AlertService} from '../../shared/services/alert.service';


@Component({
    selector: 'app-expert-system',
    templateUrl: './expert-system.component.html',
    styleUrls: ['./expert-system.component.scss']
})
export class ExpertSystemComponent implements OnInit, OnDestroy {

    @Output() changeToLegalTree: EventEmitter<void> = new EventEmitter();
    @Output() loaded: EventEmitter<void> = new EventEmitter();

    questions: QuestionInterface[] = [];
    selectedResponse: string[] = [];
    loadingQuestion: boolean = false;

    private questionsSubscription: Subscription;
    private selectedResponseSubscription: Subscription;

    @ViewChild('searchBox') searchBox: PerfectScrollbarComponent;

    constructor(private alertService: AlertService,
                private listDocumentsService: ListDocumentsService,
                private loadedDocumentsService: LoadedDocumentsService) { }

    ngOnInit() {
        this.questions = this.loadedDocumentsService.getQuestions();
        this.selectedResponse = this.loadedDocumentsService.getResponses();
        this.loadedDocumentsService.reloadDocuments();
        if (this.questions.length < 1) {
            this.loadNextQuestion();
        } else {
            setTimeout( () => this.loaded.emit(), 0 );
        }
        this.questionsSubscription = this.loadedDocumentsService.questionsObservable().subscribe(res => {
            this.questions = res;
            this.loaded.emit();
            if ((!res || res.length === 0)) {
                this.loadNextQuestion();
            }
        });
        this.selectedResponseSubscription = this.loadedDocumentsService.selectedResponseObservable().subscribe(res => {
            this.selectedResponse = res;
        });
    }

    ngOnDestroy() {
        if (this.questionsSubscription) {
            this.questionsSubscription.unsubscribe();
        }
        if (this.selectedResponseSubscription) {
            this.selectedResponseSubscription.unsubscribe();
        }
    }

    async optionClicked(selectedResponseId: string, index: number) {
        if (!this.loadingQuestion) {
            this.loadingQuestion = true;
            try {
                if (this.selectedResponse[index] && this.selectedResponse[index] === selectedResponseId) {
                    this.selectedResponse = this.selectedResponse.slice(0, index);
                    this.loadedDocumentsService.setSelectedResponse(this.selectedResponse);
                    if (this.questions.slice(0, index).length > 0) {
                        this.loadedDocumentsService.setQuestions(this.questions.slice(0, index));
                        await this.loadNextQuestion(this.selectedResponse[this.selectedResponse.length - 1]);
                    } else {
                        this.loadedDocumentsService.setQuestions([]);
                    }
                } else if (this.selectedResponse[index] && this.selectedResponse[index] !== selectedResponseId) {
                    this.selectedResponse = this.selectedResponse.slice(0, index);
                    this.selectedResponse[index] = selectedResponseId;
                    this.loadedDocumentsService.setSelectedResponse(this.selectedResponse);
                    this.loadedDocumentsService.setQuestions(this.questions.slice(0, index + 1));

                    await this.loadNextQuestion(selectedResponseId);
                } else if (!this.selectedResponse[index]) {
                    this.selectedResponse.push(selectedResponseId);
                    this.loadedDocumentsService.setSelectedResponse(this.selectedResponse);

                    await this.loadNextQuestion(selectedResponseId);
                }
                this.loadingQuestion = false;
            } catch (e) {
                console.error(e);
                this.loadingQuestion = false;
                this.alertService.showAlert();
            }
        }
    }

    async loadNextQuestion(questionId?: string) {
        this.loadedDocumentsService.setDocumentsListQuestion(questionId);
        try {
            const questionResult = await this.listDocumentsService.getQuestion(questionId);
            if (questionResult.question) {
                this.questions.push(questionResult);
                this.loadedDocumentsService.setQuestions(this.questions);
                setTimeout(() => {
                    this.searchBox.directiveRef.scrollToBottom(0, 300);
                }, 0);
            }
        } catch (e) {
            console.error(e);
            await this.alertService.showAlert('Ha habido un error cargando las preguntas, por favor, vuelva a intentarlo');
        }
    }

    resetQuestions() {
        this.loadedDocumentsService.setQuestions([]);
        this.loadedDocumentsService.setSelectedResponse([]);
    }

    onChangeToLegalTree() {
        this.changeToLegalTree.emit();
    }
}
