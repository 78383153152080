import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { saveAs } from 'file-saver';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  DocPreviewComponent, DocumentsService,
  OwnedDocumentsService, FillDocumentService
} from 'milcontratos';
import { FilledDocument, LegalDocument } from 'milcontratos-database';

import { AlertService } from '../shared/services/alert.service';


@Component({
  selector: 'app-completed-document',
  templateUrl: './completed-document.component.html',
  styleUrls: ['./completed-document.component.scss']
})
export class CompletedDocumentComponent implements OnInit {

  PDFStatus = 'Descarga PDF';
  DOCXStatus = 'Descarga DOCX';

  document_id = '';
  id = '';
  filledDocument: FilledDocument;
  legalDocument: LegalDocument;

  renderedDocPreview: boolean = false;
  displayDoc: boolean = false;
  loading: boolean = true;
  isResponsive: boolean = false;

  pdfUrl: SafeResourceUrl;
  private pdfUrlPrefix: string = '//drive.google.com/viewerng/viewer?embedded=true&url=';

  feedbackDownload: boolean = false;

  @ViewChild('preview') preview: DocPreviewComponent;
  tabs: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private deviceDetectorService: DeviceDetectorService,
    private docService: DocumentsService,
    private fillDocumentService: FillDocumentService,
    private http: HttpClient,
    private ownedService: OwnedDocumentsService,
    private sanitizer: DomSanitizer,) { }

  async ngOnInit() {
      console.log('inside ngoninit de completed-document');


      this.isResponsive = this.deviceDetectorService.isTablet() || this.deviceDetectorService.isMobile();
    this.getUrlParams();
    await this.getDocument();
    if (this.isResponsive || this.filledDocument.annexes.length > 0 || this.filledDocument.overwriteDocument) {
      this.loadPdf();
    }
  }

  getUrlParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.document_id = params['document_id'];
      this.id = params['id'];
    });
  }

  async getDocument() {
    try {
      await this.docService.getPermissionToSeeDocument(this.document_id, this.id);
      this.filledDocument = await this.ownedService.getCompletedFilledDocument(this.id);

      this.legalDocument = await this.docService.getCompletDocumentById(this.document_id);

      this.displayDoc = true;
    } catch (e) {
      await this.alertService.showAlert('Ha habido un error al cargar el documento.');
    }
  }

  updateWholeDocument() {
    this.loading = false;
    if (!this.renderedDocPreview) {
      this.renderedDocPreview = true;

      if (this.filledDocument.signatures && this.filledDocument.signatures.length > 0) {
        // add barcode
        for (let i = 0; i < this.filledDocument.signatures.length; i++) {
          this.preview.addSignature('id' + i, this.filledDocument.signatures[i].hash);
        }
      }

      this.fillDocumentService.init(this.legalDocument, this.filledDocument, this.preview);
    }
  }

  async onChangeDocumentLanguage(language: string) {
    await this.fillDocumentService.changeLanguage(language);
  }

  async downloadDocPdf() {
    this.PDFStatus = 'Generando ...';
    try {
      const render = await this.ownedService.renderCompletedFilledDocuments(this.filledDocument.id);
      const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
        if (url) {
          this.http.get(url, { responseType: 'blob' }).subscribe(
            response => { // download file
              const blob = new Blob([response],
                { type: 'application/pdf' });
              const filename = 'document.pdf';
              saveAs(blob, filename);
              this.PDFStatus = 'Descarga PDF';
            },
            error => {
              console.error(`Error: ${error.message}`);
            }
          );
          subscriber.unsubscribe();
        }
      });
    } catch (e) {
      await this.alertService.showAlert('Ha habido un error descargando el documento.');
      this.PDFStatus = 'Descarga PDF';
    }

  }

  async downloadDocDOCX() {
    this.feedbackDownload = true;
    this.DOCXStatus = 'Generando ...';
    try {
      const render = await this.ownedService.renderCompletedFilledDocuments(this.filledDocument.id);
      const subscriber = this.docService.getUrlResource(render.docxPath).subscribe((url) => {
        if (url) {
          this.http.get(url, { responseType: 'blob' }).subscribe(
            response => { // download file
              const blob = new Blob([response],
                { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
              const filename = 'document.docx';
              saveAs(blob, filename);
              this.DOCXStatus = 'Descarga DOCX';
              this.feedbackDownload = false;
            },
            error => {
              console.error(`Error: ${error.message}`);
              this.feedbackDownload = false;
            }
          );
          subscriber.unsubscribe();
        }
      });
    } catch (e) {
      await this.alertService.showAlert('Ha habido un error descargando el documento.');
      this.DOCXStatus = 'Descarga DOCX';
      this.feedbackDownload = false;
    }
  }

  async loadPdf() {
    /*(<any>window).pdfWorkerSrc = 'https://cdn.milcontratos.com/pdf.worker.js';*/
    /*(<any>window).pdfWorkerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.worker.js';*/
    const render = await this.ownedService.renderInProgressFilledDocuments(this.filledDocument.id);
    const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
      if (url) {
        this.pdfUrl = url;
        subscriber.unsubscribe();
      }
    });
    this.loading = false;
  }
}
