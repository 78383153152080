import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

import {OwnedDocumentsService} from 'milcontratos';
import {ChangeEvent} from 'angular2-virtual-scroll';

import {AlertService} from '../../shared/services/alert.service';


@Component({
  selector: 'app-documents-reviewing',
  templateUrl: './documents-reviewing.component.html',
  styleUrls: ['./documents-reviewing.component.scss']
})
export class DocumentsReviewingComponent implements OnInit {

  documents: any[] = [];
  cursorPage: any;
  filter: string = '';
  private loadingDocs: boolean = true;
  removingDocumentId: string = '';

  // @Output() selectedDocumentToEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDocumentToAddSigners: EventEmitter<any> = new EventEmitter<any>();

  constructor(private alertService: AlertService,
              private ownedServices: OwnedDocumentsService,
              private router: Router) { }

  async ngOnInit() {
    await this.getOwnedDraftDocuments();
  }

  async getOwnedDraftDocuments() {
    try {
      this.loadingDocs = true;
      const doc = await this.ownedServices.getPaginateReviewingFilledDocuments(10, this.cursorPage);
      this.cursorPage = doc.cursor;
      this.documents = doc.documents;
      this.loadingDocs = false;
    } catch (e) {
      await this.alertService.showAlert('Ha habido un error al cargar los documentos.');
    }
  }

  selectDocumentToaddSigners(document) {
    // this.selectedDocumentToAddSigners.emit(document);
    this.router.navigate(['/add-signers', document.id]);
  }

  selectDocumentToEdit(document) {
    this.router.navigate(['/fill-document', document.id]);
  }

  async remove(document) {
    try {
      if (!this.removingDocumentId) {
        this.removingDocumentId = document.id;
        await this.ownedServices.removeFilledDocument(document.id, document.document_id);
        this.cursorPage = undefined;
        await this.getOwnedDraftDocuments();
        const documentToRemove = this.documents.filter(d => d.id === document.id);
        if (documentToRemove.length > 0) {
          const indexToRemove = this.documents.indexOf(documentToRemove[0]);
          if (indexToRemove > -1) {
            this.documents.splice(indexToRemove, 1);
          }
        }
        await this.alertService.showAlert('Documento eliminado con éxito.', 'success', 'Ok', 'Éxito');
        this.removingDocumentId = '';
      }
    } catch (e) {
      console.log(e);
      this.removingDocumentId = '';
      await this.alertService.showAlert();
    }
  }

  async onDetectionScrollChange(evt: ChangeEvent) {
    try {
      if (evt.start === 0) {
        // Ir para atrás.
      } else if (evt.end === this.documents.length - 1) {
        // Ir para delante.
        if (!this.loadingDocs) {
          this.loadingDocs = true;
          const doc = await this.ownedServices.getPaginateReviewingFilledDocuments(10, this.cursorPage);
          this.cursorPage = doc.cursor;
          this.documents.push(...doc.documents);
          this.loadingDocs = false;
        }
      }
    } catch (e) {
      await this.alertService.showAlert('Ha habido un error al cargar los documentos.');
    }
  }

  searchDoc(value: string) {
    this.filter = value.toLowerCase();
  }
}
