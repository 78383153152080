import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit, DoCheck {

  showCookiesBanner: boolean = true;
  mCookies: boolean;
  myLocalStorage = {};

  constructor(private router: Router) { }

  ngOnInit() {
    this.mCookies = localStorage.getItem('apismartcontracts-cookies-config') ? (/true/i).test(localStorage.getItem('apismartcontracts-cookies-config')) : null;
    this.showCookiesBanner = this.mCookies === null;
    window['dataLayer'] = window['dataLayer'] || [];

    if (this.mCookies) {
      this.mcCookiesAccepted();
    }
  }

  ngDoCheck() {
    this.manageLocalStorage();
  }

  acceptCookies() {
    localStorage.setItem('apismartcontracts-cookies-accepted', 'true');
    this.showCookiesBanner = false;
    this.mcCookiesAccepted();
    this.saveLocalStorage();
  }

  showMoreInfo() {
    this.router.navigate(['/cookies']);
  }

  mcCookiesAccepted() {
    // console.log("CHECK DOMAIN", this.checkUrl());

    function gtag(arg1, arg2) {
      window['dataLayer'].push(arguments);
    }

    if (this.checkUrl() == -1) {
      // Global site tag (gtag.js) - Google Analytics
      let script = document.createElement("script");
      script.async = true;
      script.type = 'text/javascript';
      script.src = "https://www.googletagmanager.com/gtag/js?id=UA-77905875-1";
      document.head.appendChild(script);

      gtag('js', new Date());
      gtag('config', 'UA-77905875-1');
    }
  }

  rejectCookies() {
    localStorage.setItem('apismartcontracts-cookies-config', 'false');
    this.showCookiesBanner = false;
  }

  checkUrl() {
    let url = window.location.hostname;
    return url.search("dev") && url.search("preprod") && url.search("localhost");
  }

  manageLocalStorage() {
    this.tempLocalStorage();

    if (this.showCookiesBanner) {
      localStorage.clear();
    } else {
      if (this.mCookies == false && localStorage.length > 1) {
        localStorage.clear();
        localStorage.setItem('apismartcontracts-cookies-config', String(this.mCookies));
      }
    }
  }

  tempLocalStorage() {
    if (localStorage != null) {
      for (let i = 0; i < localStorage.length; i++) {
        this.myLocalStorage[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
      }
    }
  }

  saveLocalStorage() {
    if (this.myLocalStorage != null) {
      for (let key in this.myLocalStorage) {
        localStorage.setItem(key, this.myLocalStorage[key]);
      }
    }
  }
}
