import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import {Subscription} from 'rxjs';
import {
  DocPreviewComponent, DocumentsService, OwnedDocumentsService,
  SignatureService, UserService, FillDocumentService
} from 'milcontratos';
import {saveAs} from 'file-saver';
import {FilledDocument, LegalDocument} from 'milcontratos-database';
// import {User} from 'firebase';
import * as firebase from 'firebase/app';
import {DeviceDetectorService} from 'ngx-device-detector';

import {AlertService} from '../../shared/services/alert.service';


@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss']
})
export class SignDocumentComponent implements OnInit, OnDestroy {

  @Output() stepsCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  step: number = 1;
  smsCode: string = '';
  phone: string = '';
  feedbackDownload: boolean = false;
  notValidSigner: boolean = false;
  blockButton: boolean = false;
  loading: boolean = true;
  isResponsive: boolean = false;

  pdfUrl: SafeResourceUrl;
  private pdfUrlPrefix: string = '//drive.google.com/viewerng/viewer?embedded=true&url=';

  filledDocument: FilledDocument;
  document: LegalDocument;
  private routeSubscription: Subscription;
  private userSubscription: Subscription;

  signId: string;
  ownerId: string;
  filledDocumentId: string;
  emailCode: string;

  renderedDocPreview: boolean = false;

  private isUserAnonymous: boolean = false;

  PDFStatus = 'Descarga PDF';

  @ViewChild('preview') preview: DocPreviewComponent;
  tabs: number = 0;

  signHash: string;

  constructor(private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private deviceDetectorService: DeviceDetectorService,
              private docService: DocumentsService,
              private fillDocumentService: FillDocumentService,
              private http: HttpClient,
              private ownerService: OwnedDocumentsService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private signService: SignatureService,
              private userService: UserService) { }

  ngOnInit() {
    this.isResponsive = this.deviceDetectorService.isTablet() || this.deviceDetectorService.isMobile();
    this.getUrlParams();
    this.userSubscription = this.userService.getCurrentUserObs().subscribe(async (res) => {
      if (!res) {
        const user: firebase.User = await this.userService.signInAnonymously();
        if (user.isAnonymous) {
          this.isUserAnonymous = true;
        }
      }
      if (this.emailCode) {
        try {
          await this.ownerService.verifySigningEmailCode(this.signId, this.ownerId, this.filledDocumentId, this.emailCode);
        } catch (e) {
          console.error(e);
          this.notValidSigner = true;
        }
      }
      if (!this.notValidSigner) {
        await this.getDocument();
        if (this.isResponsive || this.filledDocument.annexes.length > 0 || this.filledDocument.overwriteDocument) {
          this.loadPdf();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.isUserAnonymous) {
      this.userService.signOut();
    }
  }

  getUrlParams () {
      this.routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
        this.ownerId = params['ownerId'];
        this.signId = params['signId'];
        this.filledDocumentId = params['filledDocumentId'];
        this.emailCode = params['emailCode'];
      });
  }

  async getDocument() {
    try {
      console.log('try');
      
      await this.docService.getPermissionToSeeFilledDocument(this.filledDocumentId, this.signId, this.emailCode, this.ownerId);
      console.log('PERMISSION DOCuMENT',this.docService.getPermissionToSeeFilledDocument(this.filledDocumentId, this.signId, this.emailCode, this.ownerId) );
      
      this.filledDocument = await this.ownerService.getFilledDocument(this.filledDocumentId, this.ownerId);
      console.log('filled doc', this.filledDocument);
      
      await this.docService.getPermissionToSeeDocumentSign(this.filledDocument.document_id, this.signId, this.ownerId,
        this.filledDocumentId, this.emailCode);
        console.log(this.docService.getPermissionToSeeDocumentSign(this.filledDocument.document_id, this.signId, this.ownerId,
          this.filledDocumentId, this.emailCode));
        
      this.document = await this.docService.getCompletDocumentById(this.filledDocument.document_id);
      console.log(this.document);
      
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert('Ha habido un error al cargar el documento.');
    }
  }

  async updateWholeDocument() {
    this.loading = false;
    if (!this.renderedDocPreview) {
      this.renderedDocPreview = true;

      if (this.filledDocument.signatures && this.filledDocument.signatures.length > 0) {
        // add barcode
        for (let i = 0; i < this.filledDocument.signatures.length; i++) {
          this.preview.addSignature('id' + i, this.filledDocument.signatures[i].hash);
        }
      }

      this.fillDocumentService.init(this.document, this.filledDocument, this.preview);
    }
  }

  async onChangeDocumentLanguage(language: string) {
    await this.fillDocumentService.changeLanguage(language);
  }

  validatePhone(): boolean {
    return Number.isInteger(parseInt(this.phone, 10));
  }

  async sendPhone(resend: boolean = false) {
    this.blockButton = true;
    if (this.validatePhone()) {
      try {
        const phone = this.phone.replace(/\s/g, '');
        const sufix = '+34';
        const parsePhone = sufix + phone;

        if (this.emailCode) {
          await this.signService.getPhoneCodeByEmail(this.signId, parsePhone, this.ownerId, this.filledDocumentId, this.emailCode);
        } else {
          await this.signService.getPhoneCodeByAssignation(this.signId, parsePhone);
        }

        if (!resend) {
          this.nextStep();
        }

      } catch (e) {
        console.error(e);
        await this.alertService.showAlert();
      }
    } else {
      await this.alertService.showAlert('El teléfono no es valido');
    }
    this.blockButton = false;
  }


  async sendCompletionCode() {
    this.blockButton = true;
    if (this.smsCode) {
      try {
        this.signHash = (await this.signService.sendCompletionCode(this.signId, this.smsCode)).hash;
        this.nextStep();
      } catch (e) {
        console.error(e);
        await this.alertService.showAlert();
      }
    }
    this.blockButton = false;
  }

  viewCompleteDocs() {
    this.router.navigateByUrl('my-documents');
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    if (this.step === 1) {
      this.router.navigateByUrl('my-documents');
    }

    this.step = 1;
  }

  async downloadDoc() {
    this.feedbackDownload = true;
    try {
      const render = await this.ownerService.renderSignerFilledDocuments(this.filledDocument.id, this.ownerId);
      const subscriber = this.docService.getUrlResource(render.docxPath).subscribe((url) => {
        if (url) {
          this.http.get(url, {responseType: 'blob' }).subscribe(
            response => { // download file
              const blob = new Blob([response],
                {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
              const filename = 'document.docx';
              saveAs(blob, filename);
              this.feedbackDownload = false;
            },
            error => {
              console.error(`Error: ${error.message}`);
              this.feedbackDownload = false;
            }
          );
          subscriber.unsubscribe();
        }
      });

    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
      this.feedbackDownload = false;
    }
  }

  async downloadDocPdf() {
    this.PDFStatus = 'Generando ...';
    try {
      const render = await this.ownerService.renderSignerFilledDocuments(this.filledDocument.id, this.ownerId);
      const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
      if (url) {
        this.http.get(url, {responseType: 'blob'}).subscribe(
          response => { // download file
          const blob = new Blob([response],
            {type: 'application/pdf'});
          const filename = 'document.pdf';
          saveAs(blob, filename);
          this.PDFStatus = 'Descarga PDF';
          },
        error => {
          console.error(`Error: ${error.message}`);
          }
        );
        subscriber.unsubscribe();
        }
      });
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert('Ha habido un error descargando el documento.');
      this.PDFStatus = 'Descarga PDF';
    }
  }

  async loadPdf() {
    const render = await this.ownerService.renderSignerFilledDocuments(this.filledDocument.id, this.ownerId);
    const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
      if (url) {
        this.pdfUrl = url;
        subscriber.unsubscribe();
      }
    });
    this.loading = false;
  }
}
