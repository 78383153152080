import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import paypal from 'paypal-checkout';
import { Subscription } from 'rxjs';
import { MaterializeAction } from '@samuelberthe/angular2-materialize';
import {
  DocumentsService,
  LegalAdviceService,
  UserService,
  DealService,
  PlatformService,
} from 'milcontratos';
import {
  Deal, LegalAdviceMeta, PlatformSettings, PublicLegalDocument,
} from 'milcontratos-database';

import { SearchDocumentService } from '../shared/services/search-document/search-document.service';
import { round } from '../shared/utils/utils';
import { ChatStateService } from '../shared/services/chat/chat-state.service';
import { AlertService } from '../shared/services/alert.service';
import { environment } from '../../environments/environment';
import { EnvironmentService } from '../environment.service';

import * as rxp from 'rxp-js';

@Component({
  selector: 'app-buy-document',
  templateUrl: './buy-document.component.html',
  styleUrls: ['./buy-document.component.scss']
})
export class BuyDocumentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('paypalButtonContainer', {static: true}) paypalButtonContainer: ElementRef;

  private routeSubscription: Subscription;
  private userSubscription: Subscription;
  private documentId: string;
  private backClicked: boolean = false;
  private iva: number;

  discoverPopup: boolean = false;

  legalAdvicePrice: number;
  legalAdviceAdded: boolean = false;
  document: PublicLegalDocument;
  price: number;
  ivaPrice: number;
  legalAdviceIvaPrice: number;
  modalActions: EventEmitter<string | MaterializeAction> = new EventEmitter<string | MaterializeAction>();

  // payer: Payer;
  loading: boolean = true;
  blockBuyButton: boolean = false;
  displayDealInput: boolean = false;
  isDocumentOwned: boolean = false;
  loadingPayButtons: boolean = true;

  dealInputText: string;
  appliedDeal: Deal = undefined;
  selectedPack: false;
  isSubscriptionSelected: boolean = false;

  constructor(private alertService: AlertService,
    private chatState: ChatStateService,
    private documentsService: DocumentsService,
    private legalAdviceService: LegalAdviceService,
    private route: ActivatedRoute,
    private router: Router,
    private searchDocumentService: SearchDocumentService,
    private userService: UserService,
    private dealService: DealService) { }

  ngAfterViewInit() {
    this.userSubscription = this.userService.getCurrentUserObs().subscribe(async (us) => {
      if (us) {
        this.loadingPayButtons = true;
        paypal.Button.render({
          style: {
            size: 'medium',
            label: 'pay',
            tagline: false,
          },
          env: environment.paypalMode, // sandbox | production

          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,

          // payment() is called when the button is clicked
          payment: await this.documentsService.getStartPaypal(this.documentId, this.legalAdviceAdded, false,
            undefined, undefined, (e) => this.openModal(e)
          ),

          // onAuthorize() is called when the buyer approves the payment
          onAuthorize: await this.documentsService.getCompletePaypal(
            (err, response) => {
              if (err) {
                this.openModal();
              } else {
                this.router.navigate(['/purchase-document-completed', this.documentId]);
              }
            }
          ),

        }, '#paypal-button-container');
      } 
      if (!(this.selectedPack !== undefined && !this.legalAdviceAdded) && !(this.isSubscriptionSelected && !this.legalAdviceAdded) &&
      !(this.appliedDeal && this.appliedDeal.discountPercentage >= 100 && !this.legalAdviceAdded)) {
      const jsonFromServerSdk = await this.documentsService.getRealexHPP(this.documentId, this.isSubscriptionSelected,
        this.selectedPack ? this.selectedPack : undefined, this.legalAdviceAdded,
        this.appliedDeal ? this.appliedDeal.textId : undefined);
      if (environment.paypalMode === 'sandbox') {
        // rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
        rxp.RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
      } else {
        rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
      }
      
      // rxp.RealexHpp.init('cardPayment', environment.baseUrl + '/purchase-document-completed/' + this.documentId, jsonFromServerSdk);
      rxp.RealexHpp.init('cardPayment', environment.baseUrl + '/purchase-document-completed/' + this.documentId, jsonFromServerSdk);
    }
    this.loadingPayButtons = false;
    });
  }

  async ngOnInit() {
    try {
      this.routeSubscription = this.route.params.subscribe((params) => {
        this.documentId = params['document_id'];
      });
      this.iva = await this.documentsService.getIVA();
      this.document = await this.documentsService.getPublicDocumentById(this.documentId);
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      const legalAdvice: LegalAdviceMeta = await this.legalAdviceService.getLegalAdviceDocument();
      this.legalAdvicePrice = legalAdvice.price;
      this.legalAdviceIvaPrice = round(legalAdvice.price + legalAdvice.price * (this.iva / 100), 2);
      this.loading = false;
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
    }
  }

  ngOnDestroy() {
    if (!this.backClicked) {
      this.searchDocumentService.setComesFromBack(false);
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async addLegalAdvice() {
    try {
      this.loadingPayButtons = true;
      this.legalAdviceAdded = true;
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id, this.legalAdviceAdded,
        false, undefined);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id, this.legalAdviceAdded,
        false, undefined);
      await this.reRenderPaypal();
    } catch (e) {
      console.log(e);
      this.loadingPayButtons = false;
      await this.alertService.showAlert();
    }
  }

  async removeLegalAdvice() {
    try {
      this.loadingPayButtons = true;
      this.legalAdviceAdded = false;
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      await this.reRenderPaypal();
    } catch (e) {
      console.log(e);
      this.loadingPayButtons = false;
      await this.alertService.showAlert();
    }
  }

  openModal(e?) {
    this.isDocumentOwned = false;
    const error = e.message.match(/{([\s\S]*?)^}/gm);
    if (JSON.parse(error[0]).message === 'Already owns document') {
      this.isDocumentOwned = true;
    }
    this.modalActions.emit({ action: 'open', params: [] });
  }

  async reRenderPaypal() {
    this.loadingPayButtons = true;
    for (const child of this.paypalButtonContainer.nativeElement.children) {
      child.remove();
    }
    // sample coding using milcontratos' backend
    paypal.Button.render({
      style: {
        size: 'medium',
        label: 'pay',
        tagline: false,
      },
      env: environment.paypalMode, // sandbox | production

      // Show the buyer a 'Pay Now' button in the checkout flow
      commit: true,

      // payment() is called when the button is clicked
      payment: await this.documentsService.getStartPaypal(this.documentId, this.legalAdviceAdded, false,
        this.appliedDeal ? this.appliedDeal.textId : undefined, undefined, (e) => this.openModal(e)
      ),

      // onAuthorize() is called when the buyer approves the payment
      onAuthorize: await this.documentsService.getCompletePaypal(
        (err, response) => {
          if (err) {
            this.openModal();
          } else {
            this.router.navigate(['/purchase-document-completed', this.documentId]);
          }
        }
      ),
    }, '#paypal-button-container');
    if (!(this.selectedPack !== undefined && !this.legalAdviceAdded) && !(this.isSubscriptionSelected && !this.legalAdviceAdded) &&
      !(this.appliedDeal && this.appliedDeal.discountPercentage >= 100 && !this.legalAdviceAdded)) {
      const jsonFromServerSdk = await this.documentsService.getRealexHPP(this.documentId, this.isSubscriptionSelected,
        this.selectedPack ? this.selectedPack : undefined, this.legalAdviceAdded,
        this.appliedDeal ? this.appliedDeal.textId : undefined);
      if (environment.paypalMode === 'sandbox') {
        // rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
        rxp.RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
      } else {
        rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
      }
      rxp.RealexHpp.init('cardPayment', environment.baseUrl + '/purchase-document-completed/' + this.documentId, jsonFromServerSdk);
    }
    this.loadingPayButtons = false;
  }

  async goBack() {
    this.searchDocumentService.setComesFromBack(true);
    this.backClicked = true;
    await this.router.navigate(['/search-document']);
  }

  completePaymentCard(completed: boolean) {
    if (completed) {
      this.router.navigate(['/purchase-document-completed', this.documentId]);
    }
  }

  displayChat() {
    this.chatState.changeState(true);
  }

  async addDeal() {

    if (this.dealInputText) {

      this.dealInputText = this.dealInputText.trim();

      try {
        this.appliedDeal = await this.dealService.getUserDocumentDeal(this.dealInputText);

        const isDealTypeValid: boolean = this.dealService.isValidDealForTypes(this.appliedDeal, this.document.types);
        const isDealUsed: boolean = await this.dealService.isDealUsed(this.appliedDeal.textId);

        if (isDealTypeValid && !isDealUsed) {
          this.displayDealInput = false;
          this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
            this.legalAdviceAdded, this.selectedPack !== undefined || this.isSubscriptionSelected, this.appliedDeal);
          this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
            this.legalAdviceAdded, this.selectedPack !== undefined || this.isSubscriptionSelected, this.appliedDeal);
            console.log('test', this.ivaPrice);
            console.log('test', this.legalAdviceAdded);
          await this.reRenderPaypal();
        } else {
          this.appliedDeal = undefined;
          await this.alertService.showAlert(!isDealTypeValid ?
            'El código de descuento introducido no es válido para este tipo de documento.' :
            'El código de descuento introducido ya ha sido usado.');
        }
      } catch (error) {
        this.appliedDeal = undefined;
        console.error(error);
        if (error.errors && error.errors[0] === 'Not existent deal') {
          await this.alertService.showAlert('El código de descuento introducido no existe.');
        } else if (error.errors && error.errors[0] === 'Not valid deal') {
          await this.alertService.showAlert('El código de descuento introducido no es válido.');
        } else if (error.errors && error.errors[0] === 'Used yet') {
          await this.alertService.showAlert('El código de descuento introducido ya ha sido usado.');
        } else if (error.errors && error.errors[0] === 'Deal expired') {
          await this.alertService.showAlert('El código de descuento introducido ha caducado.');
        } else {
          await this.alertService.showAlert();
        }
      }
    } else {
      await this.alertService.showAlert('Debes introducir un código de descuento');
    }
  }

  async cancelDeal() {
    try {
      this.loadingPayButtons = true;
      this.appliedDeal = undefined;
      this.dealInputText = '';
      this.displayDealInput = false;
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
        this.legalAdviceAdded, this.selectedPack !== undefined || this.isSubscriptionSelected, this.appliedDeal);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
        this.legalAdviceAdded, this.selectedPack !== undefined || this.isSubscriptionSelected, this.appliedDeal);
      await this.reRenderPaypal();
    } catch (e) {
      console.log(e);
      this.loadingPayButtons = false;
      await this.alertService.showAlert();
    }
  }
}
