import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { PlatformService, UserProfileAdminService, UserService } from 'milcontratos';

import { AlertService } from '../shared/services/alert.service';

import { environment as env } from '../../environments/environment';
import { EnvironmentService } from '../environment.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;

  email: string = '';
  username: string = '';
  password: string = '';
  rePassword: string = '';
  conditionsAcceptance: boolean = false;
  advertice: boolean = false;
  showFillFormError: boolean = false;
  showPasswordMatchError: boolean = false;

  showEmailAlreadyExistsError: boolean = false;
  showInvalidEmailError: boolean = false;
  showInvalidPasswordError: boolean = false;

  signingUp: boolean = false;

  constructor(private alertService: AlertService,
    private router: Router,
    private userService: UserService,
    private _userAdmin: UserProfileAdminService,
    private _plat: PlatformService,
    private _env: EnvironmentService) { }

  ngOnInit() {
    this._plat.getSettingsPlatform(this._env.platformId()).then(res => {
      console.log(res);
      
    })
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  validator(): boolean {
    return this.username !== '' && this.password !== '' && this.rePassword !== '' && this.conditionsAcceptance;
  }

  passwordMatch(): boolean {
    return this.password === this.rePassword;
  }

  async signUp() {
    this.showEmailAlreadyExistsError = false;
    this.showInvalidEmailError = false;
    this.showInvalidPasswordError = false;
    this.showFillFormError = false;
    this.showPasswordMatchError = false;
    // console.log('Validator: ' + this.validator());
    if (!this.validator()) {
      // console.log('Entro en el validator');
      this.showFillFormError = true;
    } else if (!this.passwordMatch()) {
      // console.log('La contraseña no coincide');
      this.showPasswordMatchError = true;
    } else if (this.validator() && this.passwordMatch()) {
      // console.log('Validator: ' + this.validator() + ' Password Match: ' + this.passwordMatch());
      try {
        this.signingUp = true;
        await this.userService.signInApismartcontracts2();

        try {
          await this._userAdmin.addUser(
            'apismartcontracts-' + this.username,
            this.email,
            this.password,
            env.platformId,
            false
          );

          await this.userService.signOut();
          await this.userService.platformLogin(this.email, this.password);
          await this.userService.sendEmailVerification();
        } catch (e) {
          console.error(e);
          await this.userService.signOut();
          if (e === 'User already exists') {
            this.router.navigate(['/sign-up']);
            await this.alertService.showAlert('Este email ya ha sido registrado.');
          }
        }

        this.signingUp = false;
        this.router.navigate(['/transition']);
      } catch (error) {
        console.error(error);
        if (error.code === 'auth/email-already-exists') {
          this.showEmailAlreadyExistsError = true;
        } else if (error.code === 'auth/invalid-email') {
          this.showInvalidEmailError = true;
        } else if (error.code === 'auth/invalid-password' || error.code === 'auth/weak-password') {
          this.showInvalidPasswordError = true;
        } else if (error === 'User not found') {
          this.router.navigate(['/transition']);
        } else {
          await this.alertService.showAlert('Ha habido un error, por favor, compruebe que sus credenciales son correctos' +
          'y vuelva a intentarlo.');
        }
      }
    }
  }

  conditionsAcceptanceChange(event) {
    this.conditionsAcceptance = event;
  }

  adverticeChange(event) {
    this.advertice = event;
  }
}
