import { Component, OnInit } from '@angular/core';

const PRICING = [{
  name: 'Nuevo ¹',
  comprado: 'Si',
  precio: '-',
  precioad: '-',
  max: 'Ilimitado',
  desc: '(1) Al comprar la licencia de un documento, en el primer uso, se le aplican los precios establecidos.'
},
{
  name: 'Plantilla ²',
  comprado: 'No',
  precio: 2.50,
  precioad: 1.00,
  max: 'Ilimitado',
  desc: '(2) Los siguientes usos de un documento como plantilla a partir del primer uso.'
},
{
  name: 'Coste 0 ³',
  comprado: 'No',
  precio: 2.50,
  precioad: 1.00,
  max: 'Ilimitado',
  desc: '(3) Todos los usos de los documentos con licencia de uso a coste 0.'
}]

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  pricing = PRICING


  constructor() { }

  ngOnInit(): void {
  }

  isNumber(val): boolean { return typeof val === 'number' }

}
