import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import paypal from 'paypal-checkout';
import { Subscription } from 'rxjs';
import { MaterializeAction } from '@samuelberthe/angular2-materialize';
import {
  DocumentsService,
  UserService,
  OwnedDocumentsService,
  SignatureService,
  DealService,
} from 'milcontratos';

import {
  FilledDocument,
  PublicLegalDocument,
  Deal
} from 'milcontratos-database';

import { SearchDocumentService } from '../shared/services/search-document/search-document.service';
import { ChatStateService } from '../shared/services/chat/chat-state.service';
import { AlertService } from '../shared/services/alert.service';
import { environment } from '../../environments/environment';

import * as rxp from 'rxp-js';

@Component({
  selector: 'app-buy-signature',
  templateUrl: './buy-signature.component.html',
  styleUrls: ['./buy-signature.component.scss']
})
export class BuySignatureComponent implements OnInit, OnDestroy {

  @ViewChild('paypalButtonContainer') paypalButtonContainer: ElementRef;

  private routeSubscription: Subscription;
  private userSubscription: Subscription;
  private documentId: string;
  filledDocumentId: string;
  private backClicked: boolean = false;

  discoverPopup: boolean = false;

  legalAdvicePrice: number;
  legalAdviceAdded: boolean = false;
  document: PublicLegalDocument;
  filledDoc: FilledDocument;
  price: number;
  ivaPrice: number;
  legalAdviceIvaPrice: number;
  modalActions: EventEmitter<string | MaterializeAction> = new EventEmitter<string | MaterializeAction>();

  loading: boolean = true;
  blockBuyButton: boolean = false;
  displayDealInput: boolean = false;
  isDocumentOwned: boolean = false;
  loadingPayButtons: boolean = true;

  filledDocument: FilledDocument;

  signNumber: number = 0;

  dealInputText: string;
  appliedDeal: Deal = undefined;
  selectedPack: false;
  isSubscriptionSelected: boolean = false;

  constructor(private alertService: AlertService,
    private chatState: ChatStateService,
    private route: ActivatedRoute,
    private router: Router,
    private searchDocumentService: SearchDocumentService,
    private _ownedServices: OwnedDocumentsService,
    private _signature: SignatureService,
    private userService: UserService,
    private _documentsService: DocumentsService) { }

  async ngOnInit() {
    try {
      this.routeSubscription = this.route.params.subscribe((params) => {
        this.documentId = params['document_id'];
      });
      await this.startBuySignature();
      this.renderPaypal();

      this.loading = false;
    } catch (e) {
      this.alertService.showAlert("Ha ocurrido un error, vuelva a intentarlo.")
      console.error(e);
    }
  }

  async startBuySignature() {
    this.filledDocument = await this._ownedServices.getFilledDocument(this.documentId);
    this.filledDocumentId = this.filledDocument.id;

    const signPrice = await this._signature.getSignatures(this.filledDocumentId);
    const numSigners = signPrice.signatures;
    this.signNumber = numSigners;
    this.price = signPrice.price;
    this.ivaPrice = signPrice.finalPrice;
  }

  renderPaypal() {
    this.userSubscription = this.userService.getCurrentUserObs().subscribe(async (us) => {
      if (us) {
        this.loadingPayButtons = true;
        paypal.Button.render({
          style: {
            size: 'medium',
            label: 'pay',
            tagline: false,
          },
          env: environment.paypalMode, // sandbox | production

          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,

          // payment() is called when the button is clicked
          payment: await this._signature.getStartPaypal(this.userService.getCurrentUser().uid, this.filledDocumentId, this.price, this.ivaPrice, this.signNumber, (e) => {
            this.openModal(e)
          }),
          // onAuthorize() is called when the buyer approves the payment
          onAuthorize: await this._signature.getCompletePaypal(
            (err, response) => {
              if (err) {
                this.openModal();
              } else {
                this.router.navigate(['/add-signers', this.filledDocumentId]);
              }
            }
          ),

        }, '#paypal-button-container');
      }

      const jsonFromServerSdk = await this._signature.getRealexHppSignatures(this.userService.getCurrentUser().uid,
        this.filledDocumentId, this.price * 1, this.ivaPrice * 1, this.signNumber * 1, (e) => {
          this.openModal(e)
        });
      if (environment.paypalMode === 'sandbox') {
        // rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
        rxp.RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
      } else {
        rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
      }

      // rxp.RealexHpp.init('cardPayment', environment.baseUrl + '/purchase-document-completed/' + this.documentId, jsonFromServerSdk);
      rxp.RealexHpp.init('cardPayment', environment.baseUrl + '/add-signers/' + this.filledDocumentId, jsonFromServerSdk);

      this.loadingPayButtons = false;
    });
  }

  ngOnDestroy() {
    if (!this.backClicked) {
      this.searchDocumentService.setComesFromBack(false);
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  openModal(e?) {
    this.isDocumentOwned = false;
    const error = e.message.match(/{([\s\S]*?)^}/gm);
    if (JSON.parse(error[0]).message === 'Already owns document') {
      this.isDocumentOwned = true;
    }
    this.modalActions.emit({ action: 'open', params: [] });
  }

  async goBack() {
    this.searchDocumentService.setComesFromBack(true);
    this.backClicked = true;
    await this.router.navigate(['/search-document']);
  }

  completePaymentCard(completed: boolean) {
    if (completed) {
      this.router.navigate(['/purchase-document-completed', this.documentId]);
    }
  }

  displayChat() {
    this.chatState.changeState(true);
  }
}
