import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { DocumentsService, OwnedDocumentsService, UserService } from 'milcontratos';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { FilledDocument, LegalDocument } from 'milcontratos-database';

import { AlertService } from '../shared/services/alert.service';


export interface ReviewInterface {
  title: string;
  email: string;
  phone: string;
  message: string;
  files: File[];
}


@Component({
  selector: 'app-filling-document',
  templateUrl: './filling-document.component.html',
  styleUrls: ['./filling-document.component.scss']
})
export class FillingDocumentComponent implements OnInit, OnDestroy {
  document: LegalDocument;
  filledDocument: FilledDocument;

  loadDocPreview: boolean = false;
  renderedDocPreview: boolean = false;
  selectedStep: number = 0;
  docName: string;
  sendingToReview: boolean = false;
  private sendingToDone: boolean = false;

  private userSubscription: Subscription;

  feedbackDownload: boolean = false;
  showNotificationPopup: boolean = true;
  showNotificationStored: boolean = false;

  private firstTime: boolean;

  constructor(private alertService: AlertService,
    private documents: DocumentsService,
    private http: HttpClient,
    private ownedDocService: OwnedDocumentsService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService) {
  }

  ngOnInit() {
    const mCookies = localStorage.getItem('milcontratos-filling-document-notification-popup');
    this.showNotificationPopup = mCookies === null;

    const filledDocId = this.route.snapshot.params.document_id;
    this.userSubscription = this.userService.getCurrentUserObs().pipe(first()).subscribe(async (res) => {
      if (res) {
        try {
          this.filledDocument = await this.ownedDocService.getFilledDocument(filledDocId);
          await this.documents.getPermissionToSeeDocument(this.filledDocument.document_id);
          this.document = await this.documents.getCompletDocumentById(this.filledDocument.document_id);
          const completed = await this.isInComplete()
          const draft = await this.isInDraft()
          this.valFirstTime(completed, draft)
        } catch (e) {
          console.error(e);
          await this.alertService.showAlert();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  acceptNotificationPopup() {
    console.log('showNotificationStored = ', this.showNotificationStored);
    if (this.showNotificationStored) {
      localStorage.setItem('milcontratos-filling-document-notification-popup', 'true');
    }
    this.showNotificationPopup = false;
  }

  async updateValues(values: any) {
    try {
      this.ownedDocService.setValuesFilled(this.filledDocument.id, values);
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
    }
  }

  async updateTranslatedValues(values: any) {
    try {
      this.ownedDocService.setTranslatedValuesFilled(this.filledDocument.id, values);
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
    }
  }

  async sendToReview(event: ReviewInterface) {
    try {
      if (this.filledDocument.remainingDocumentReviews !== 0) {
        const emailRegex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/g;
        const isEmailValid: boolean = emailRegex.test(event.email);
        if (!isEmailValid) {
          await this.alertService.showAlert('Debe introducir un email válido.');
        } else {
          if (!this.sendingToReview) {
            this.sendingToReview = true;
            const paths = [];
            for (let i = 0; i < event.files.length; i++) {
              paths.push(await this.ownedDocService.uploadAttachmentForReview(this.filledDocument.id, event.files[i]));
            }
            await this.ownedDocService.sendDocumentToReview(this.filledDocument.id, event.title, event.message,
              event.email, event.phone, paths);
            await this.alertService.showAlert(
              'Revisión solicitada con éxito. Un abogado se pondrá en contacto contigo lo antes posible.',
              'success', 'Ok', 'Éxito');
            this.sendingToReview = false;
            this.router.navigate(['/my-documents']);
          }
        }
      } else {
        // console.log('send to review');
      }
    } catch (e) {
      console.error(e);
      this.sendingToReview = false;
      await this.alertService.showAlert();
    }
  }

  async sendToDoneSignature() {
    try {
      if (!this.sendingToDone) {
        this.sendingToDone = true;

        const completed = await this.ownedDocService.completePurchasingSignFillDocument(this.document.id, this.filledDocument.id, this.firstTime);
        if (completed) {
          this.router.navigate(['document'], {
            queryParams: {
              document_id: this.document.id,
              id: this.filledDocument.id
            }
          })
        } else {
          if (!this.firstTime)
            this.router.navigate(['/buy-signature', this.filledDocument.id]);
          else
            this.router.navigate(['/add-signers', this.filledDocument.id]);
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  async completeDocumentImmediately() {
    try {
      if (!this.sendingToDone) {
        this.sendingToDone = true;
        const completed = await this.ownedDocService.completeFillDocument(this.document.id, this.filledDocument.id, true);
        if (completed) {
          this.router.navigate(['document'], {
            queryParams: {
              document_id: this.document.id,
              id: this.filledDocument.id
            }
          });
        } else {
          await this.alertService.showAlert();
        }
        this.sendingToDone = false;
      }
    } catch (e) {
      console.error(e);
      this.sendingToDone = false;
      await this.alertService.showAlert();
    }
  }

  async sendTitle(customTitle: string) {
    try {
      await this.ownedDocService.setCustomTitleFilledDocument(this.filledDocument.id, customTitle);
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
    }
  }

  async download() {
    try {
      this.feedbackDownload = true;
      const render = await this.ownedDocService.renderInProgressFilledDocuments(this.filledDocument.id);
      const subscriber = this.documents.getUrlResource(render.docxPath).subscribe((url) => {
        if (url) {
          const options = { responseType: 'blob' };
          this.http.get(url, { responseType: 'blob' }).subscribe(
            response => { // download file
              const blob = new Blob([response],
                { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
              const filename = 'document.docx';
              saveAs(blob, filename);
              this.feedbackDownload = false;
            },
            error => {
              this.feedbackDownload = false;
              console.error(`Error: ${error.message}`);
            }
          );
          subscriber.unsubscribe();
        }
      });
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert('Ha habido un error al descargar el documento.');
    }
  }

  onBuyReviews() {
    this.router.navigate(['/buy-review', this.filledDocument.id]);
  }

  async isInComplete() {
    let inCompleted = false;
    await this.ownedDocService.getPaginateCompletedFilledDocuments(10000).then(e => {
      e.documents.forEach(x => {
        if (x.document_id === this.filledDocument.document_id) inCompleted = true
      })
    })
    return inCompleted;
  }

  async isInDraft() {
    let inDraft = false;
    await this.ownedDocService.getPaginateFillingFilledDocuments(10000).then(e => {
      e.documents.forEach(x => {
        if (x.document_id === this.filledDocument.document_id && x.state === 'signing') inDraft = true;
      });
    })
    return inDraft;
  }

  valFirstTime(completed, draft) {
    if (!completed && !draft) this.firstTime = true
    else this.firstTime = false

    // CHECK FREE DOCUMENT
    if (this.document.price <= 0 || this.document.id === '397c9483-271d-4d7a-afda-e16fd23c8962') this.firstTime = false;

  }

}
