import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MyDocumentsComponent } from './my-documents/my-documents.component';
import { SearchDocComponent } from './search-doc/search-doc.component';
import { AuthService } from './shared/services/auth/auth.service';
import { TransitionComponent } from './transition/transition.component';
import { SignDocumentComponent } from './my-documents/sign-document/sign-document.component';
import { WaitingSignersComponent } from './my-documents/waiting-signers/waiting-signers.component';
import { CompletedDocumentComponent } from './completed-document/completed-document.component';
import { FillingDocumentComponent } from './filling-document/filling-document.component';
import { CookiesComponent } from './cookies/cookies.component';
import { PrivacityPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AddSignersComponent } from './my-documents/add-signers/add-signers.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { SupportComponent } from './support/support.component';
import { LegalWarningComponent } from './legal-warning/legal-warning.component';
import { DocumentInfoComponent } from './document-info/document-info.component';
import { AuthManagementComponent } from './auth-management/auth-management.component';
import { BuyDocumentComponent } from './buy-document/buy-document.component';
import { PurchaseDocumentCompletedComponent } from './purchase-document-completed/purchase-document-completed.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { BuySignatureComponent } from './buy-signature/buy-signature.component'
import { SignUpComponent } from './sign-up/sign-up.component';
import { PricingComponent } from './pricing/pricing.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { MyPurchasesComponent } from './my-purchases/my-purchases.component';
import { OnlineTradeConditionsComponent } from './online-trade-conditions/online-trade-conditions.component';

const appRoutes: Routes = [
    /**Coment under maintenance**/
    // { path: '', pathMatch: 'full', component: TransitionComponent },
    { path: '', pathMatch: 'full', component: HomeComponent },
    { path: 'sign-in', pathMatch: 'full', component: SignInComponent },
    { path: 'sign-up', component: SignUpComponent },
    {
        path: 'search-document',
        component: SearchDocComponent, canActivate: [AuthService], // canActivate = Restricted page only for users
        children: [
            { path: '', component: SearchDocComponent },
            { path: '**', component: SearchDocComponent },
        ]
    },
    {
        path: 'lawyer-tree',
        component: SearchDocComponent, canActivate: [AuthService],
        children: [
            { path: '', component: SearchDocComponent },
            { path: '**', component: SearchDocComponent }
        ]
    },
    { path: 'legal-document/:id', component: DocumentInfoComponent, canActivate: [AuthService] },
    { path: 'legal-document/:id/:language', component: DocumentInfoComponent, canActivate: [AuthService] },
    { path: 'legal-document/documento-vacio-modelo-firmas', component: DocumentInfoComponent, canActivate: [AuthService] },
    { path: 'my-documents', component: MyDocumentsComponent, canActivate: [AuthService] },
    { path: 'document', component: CompletedDocumentComponent, canActivate: [AuthService] },
    { path: 'support', component: SupportComponent }, // canActivate = Restricted page only for users
    { path: 'signature', component: SignDocumentComponent },
    { path: 'fill-document/:document_id', component: FillingDocumentComponent, canActivate: [AuthService] },
    { path: 'add-signers/:document_id', component: AddSignersComponent, canActivate: [AuthService] },
    { path: 'waiting-signers', component: WaitingSignersComponent, canActivate: [AuthService] },
    { path: 'home', component: HomeComponent, canActivate: [AuthService] }, // With TransitionComponent path: ''
    { path: 'transition', component: TransitionComponent },
    { path: 'cookies', component: CookiesComponent },
    { path: 'privacy-policy', component: PrivacityPolicyComponent },
    { path: 'legal-warning', component: LegalWarningComponent },
    { path: 'online-trade-conditions', component: OnlineTradeConditionsComponent },
    { path: 'auth-error', redirectTo: 'sign-in' },
    { path: 'buy-document/:document_id', component: BuyDocumentComponent, canActivate: [AuthService] },
    { path: 'purchase-document-completed/:document_id', component: PurchaseDocumentCompletedComponent, canActivate: [AuthService] },
    { path: 'verificacion', component: AuthManagementComponent },
    { path: 'buy-signature/:document_id', component: BuySignatureComponent, canActivate: [AuthService] },
    { path: 'pricing', component: PricingComponent },
    { path: 'my-data', component: UserSettingsComponent, canActivate: [AuthService] },
    { path: 'my-purchases', component: MyPurchasesComponent, canActivate: [AuthService] },
    { path: '**', component: MaintenanceComponent, pathMatch: 'full' }
    // { path: '**', redirectTo: 'search-document', pathMatch: 'full' }

    /**Uncomment under maintenance**/
    /*{ path: '', pathMatch: 'full', component: MaintenanceComponent },
    { path: '**', pathMatch: 'full', component: MaintenanceComponent }*/
];

export const appRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);
