import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { saveAs } from 'file-saver';
import {BillingUserService, DocumentsService} from 'milcontratos';
import {CompletedTransaction, CompletedTransactionFeed, InvoiceDetails} from 'milcontratos-database';
import {validate, ValidationError} from 'class-validator';

import {AlertService} from '../shared/services/alert.service';


interface MyPurchasesFormErrors {
  name: boolean;
  surname: boolean;
  email: boolean;
  emailFormat: boolean;
  phone: boolean;
  address: boolean;
  city: boolean;
  postalCode: boolean;
  province: boolean;
  country: boolean;
  companyName: boolean;
  identification: boolean;
}


@Component({
  selector: 'app-my-purchases',
  templateUrl: './my-purchases.component.html',
  styleUrls: ['./my-purchases.component.scss']
})
export class MyPurchasesComponent implements OnInit {
  invoiceDetails: InvoiceDetails;
  errors: MyPurchasesFormErrors = {
    name: false,
    surname: false,
    email: false,
    emailFormat: false,
    phone: false,
    address: false,
    city: false,
    postalCode: false,
    province: false,
    country: false,
    companyName: false,
    identification: false,
  };
  transactionFeed: CompletedTransactionFeed;
  transactions: CompletedTransaction[] = [];
  tabs: number = 0;
  generatingId: string = '';
  downloadingId: string = '';
  loadingTransactions: boolean = true;
  savingInvoiceDetails: boolean = false;

  constructor(private alertService: AlertService,
              private billingUserService: BillingUserService,
              private documentsService: DocumentsService,
              private http: HttpClient) { }

  async ngOnInit() {
    try {
      this.invoiceDetails = await this.billingUserService.getInvoiceDetails();
      if (!this.invoiceDetails) {
        this.invoiceDetails = new InvoiceDetails();
        this.invoiceDetails.isCompany = false;
      }
      this.transactionFeed = await this.billingUserService.getAllPaginateTransactions(9999);
      this.transactions = this.transactionFeed.transactions;
      this.loadingTransactions = false;
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert('Ha habido un error cargando sus compras.');
    }
  }

  async onSaveInvoiceDetails() {
    try {
      if (!this.savingInvoiceDetails) {
        this.savingInvoiceDetails = true;
        const errors = await this.validateInvoiceDetails();
        if (!errors.length) {
          await this.billingUserService.setInvoiceDetails(this.invoiceDetails);
          await this.alertService.showAlert('Datos guardados con éxito.', 'success', 'Ok', 'Éxito!');
        }
        this.savingInvoiceDetails = false;
      }
    } catch (e) {
      console.log(e);
      this.savingInvoiceDetails = false;
      await this.alertService.showAlert();
    }
  }

  async generateInvoice(transaction: CompletedTransaction) {
    try {
      const errors = await this.validateInvoiceDetails();
      if (!errors.length) {
        if (!this.generatingId) {
          this.generatingId = transaction.id;
          await this.billingUserService.generateInvoice(transaction.id, this.invoiceDetails);
          transaction.hasInvoice = true;
        }
      }
      this.generatingId = '';
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert('Ha ocurrido un error al generar la factura.');
      this.generatingId = '';
    }
  }

  async downloadInvoice(transaction: CompletedTransaction, isTicket: boolean = false) {
    try {
      if (!this.downloadingId) {
        this.downloadingId = transaction.id;
        const path = await this.billingUserService.renderInvoice(transaction.id, '');
        const subscriber = this.documentsService.getUrlResource(path).subscribe((url) => {
          if (url) {
            this.http.get(url, {responseType: 'blob'}).subscribe(
              res => {
                const filename = (isTicket ? 'ticket' : 'factura') + `_${transaction.id}.pdf`;
                saveAs(res, filename);
              },
              async error => {
                await this.alertService.showAlert('Ha ocurrido un error descargando la factura.');
                console.error(`Error: ${error.message}`);
                this.downloadingId = '';
              }
            );
            subscriber.unsubscribe();
          }
        });
        this.downloadingId = '';
      }
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert('Ha ocurrido un error descargando la factura.');
      this.downloadingId = '';
    }
  }

  async validateInvoiceDetails(): Promise<ValidationError[]> {
    Object.keys(this.errors).map((key) => this.errors[key] = 0);
    const errors: ValidationError[] = await validate(this.invoiceDetails);

    for (const error of errors) {
      if (error.property === 'email') {
        if (error.constraints['isEmail']) {
          this.errors.emailFormat = true;
        } else {
          this.errors.email = true;
        }
      } else {
        this.errors[error.property] = true;
      }
    }
    return errors;
  }
}
