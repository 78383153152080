//PROD
// export const environment = {
//   paypalMode: 'production',
//   production: true,
//   platformId: 'd833aa5a-d5da-4362-9200-8b348e34efbb',
//   firebase: {
//     apiKey: 'AIzaSyCvmo-46vtvF17yNL_wAv2opmIp7AanCmk',
//     authDomain: 'milcontratos-prod.firebaseapp.com',
//     databaseURL: 'https://milcontratos-prod.firebaseio.com',
//     projectId: 'milcontratos-prod',
//     storageBucket: 'milcontratos-prod.appspot.com',
//     messagingSenderId: '406625598274'
//   },
//   urlFunctions: 'https://us-central1-milcontratos-prod.cloudfunctions.net',
//   bucket: 'milcontratos-prod.appspot.com',
//   baseUrl: 'https://pipe-milcontratos-prod.firebaseapp.com',
//   webUrl: 'www.apismartcontracts.net'
// };

//PREPROD
// export const environment = {
//   paypalMode: 'sandbox',
//   production: true,
//   platformId: '5880675a-3564-4d78-a44f-78a03ed7d625', //API 2
//   firebase: {
//     apiKey: 'AIzaSyDBtQE79e0S7wNcKB7I6RSx5pYiw5JjjKY',
//     authDomain: 'milcontratos-preprod.firebaseapp.com',
//     databaseURL: 'https://milcontratos-preprod.firebaseio.com',
//     projectId: 'milcontratos-preprod',
//     storageBucket: 'milcontratos-preprod.appspot.com',
//     messagingSenderId: '117834018276'
//   },
//   urlFunctions: 'https://us-central1-milcontratos-preprod.cloudfunctions.net',
//   bucket: 'milcontratos-preprod.appspot.com',
//   baseUrl: 'https://pipe-apismartcontracts-preprod.firebaseapp.com',
//   webUrl: 'preprod.apismartcontracts.net',
// };

// DEV
export const environment = {
  paypalMode: 'sandbox',
  production: true,
  platformId: '8e27588e-6614-411c-97b1-d1ae05d837b7', //API 2
  firebase: {
    apiKey: 'AIzaSyDBtQE79e0S7wNcKB7I6RSx5pYiw5JjjKY',
    authDomain: 'milcontratos-dev.firebaseapp.com',
    databaseURL: 'https://milcontratos-dev.firebaseio.com',
    projectId: 'milcontratos-dev',
    storageBucket: 'milcontratos-dev.appspot.com',
    messagingSenderId: '117834018276'
  },
  urlFunctions: 'https://us-central1-milcontratos-dev.cloudfunctions.net',
  bucket: 'milcontratos-dev.appspot.com',
  baseUrl: 'https://pipe-apismartcontracts-preprod.firebaseapp.com',
  webUrl: 'dev.apismartcontracts.com',
};