import { Component, OnInit } from '@angular/core';

import { UserService } from 'milcontratos';

import { AlertService } from '../shared/services/alert.service';


@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  name: string = '';
  currentPassword: string = '';
  newPassword: string = '';
  repeatNewPassword: string = '';
  updatingUsername: boolean = false;
  updatingPassword: boolean = false;

  constructor(private alertService: AlertService,
    private userService: UserService) { }

  ngOnInit() {
    const currentUser = this.userService.getCurrentUser();
    this.name = currentUser.displayName.replace('apismartcontracts-', '');
  }

  validator(): boolean {
    return this.name !== '';
  }

  passwordMatch(): boolean {
    return this.newPassword === this.repeatNewPassword;
  }

  async updateUserName() {
    if (!this.validator()) {
      await this.alertService.showAlert('No puedes dejar el nombre sin rellenar.');
    } else if (!this.updatingUsername) {
      try {
        this.updatingUsername = true;
        await this.userService.addUserName('apismartcontracts-' + this.name);
        await this.alertService.showAlert('Nombre cambiado con éxito.', 'success', 'Ok', 'Éxito');
        this.updatingUsername = false;
      } catch (e) {
        console.log(e);
        this.updatingUsername = false;
        await this.alertService.showAlert();
      }
    }
  }

  async updatePassword() {
    if (!this.passwordMatch()) {
      await this.alertService.showAlert('La nueva contraseña no coincide.');
    } else if (!this.updatingPassword) {
      try {
        this.updatingPassword = true;
        if (this.newPassword !== '' && this.newPassword !== undefined) {
          await this.userService.changePassword(this.currentPassword, this.newPassword);
          await this.alertService.showAlert('Contraseña cambiada con éxito.', 'success', 'Ok', 'Éxito');
        } else {
          await this.alertService.showAlert('Debes introducir una contraseña nueva.');
        }
        this.updatingPassword = false;
      } catch (error) {
        console.log(error);
        this.updatingPassword = false;
        if (error.code === 'auth/wrong-password') {
          await this.alertService.showAlert('Contraseña incorrecta. Por favor, asegúrate de escribir ' +
            'correctamente tu contraseña actual.');
        } else if (error.code === 'auth/invalid-password' || error.code === 'auth/weak-password') {
          await this.alertService.showAlert('La contraseña no es válida. Debe tener un mínimo de 6 carácteres.');
        } else {
          await this.alertService.showAlert();
        }
      }
    }
  }
}
