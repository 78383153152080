import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-trade-conditions',
  templateUrl: './online-trade-conditions.component.html',
  styleUrls: ['./online-trade-conditions.component.scss']
})
export class OnlineTradeConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
